import mediator from "@tvg/mediator";
import { LoginModalMediatorEventType } from "@tvg/ts-types/Amplitude";
import type { SessionHint } from "@fanduel/account-contract";

export const loginModalAFEvent = (hint: SessionHint, module?: String) => {
  if (hint === "Local" || hint === "Silent") {
    return;
  }

  mediator.base.dispatch({
    type:
      hint === "NewUser"
        ? LoginModalMediatorEventType.REGISTRATION_MODAL_OPEN
        : LoginModalMediatorEventType.LOGIN_MODAL_OPEN,
    payload: {
      // TODO: Review this module event, the old implemetation everything is join_bar, but this looks like wrong.
      module: module || "join_bar"
    }
  });
};
