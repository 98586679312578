import mediator from "@tvg/mediator";

interface CallbackPayload {
  payload: Function;
}

interface TriggerCallbackPayload<T> {
  type: "TRIGGER_CALLBACK";
  payload: Array<T>;
}

let callback: Function | null = null;

export const setGlobalCallback = (cb: Function) => {
  mediator.base.dispatch({
    type: "SET_CALLBACK",
    payload: cb
  });
};

export const triggerGlobalCallback = <T>(...parameters: Array<T>) => {
  mediator.base.dispatch({
    type: "TRIGGER_CALLBACK",
    payload: parameters
  });
};

export const clearCallback = () => {
  mediator.base.dispatch({
    type: "CLEAR_CALLBACK"
  });
};

export const globalCallbackSubscriptions = () => {
  mediator.base.subscribe("SET_CALLBACK", (data: CallbackPayload) => {
    if (typeof data.payload === "function") {
      callback = data.payload;
    }
  });

  mediator.base.subscribe(
    "TRIGGER_CALLBACK",
    <T>({ payload }: TriggerCallbackPayload<T>) => {
      if (typeof callback === "function") {
        const parameters = payload || [];
        callback(...parameters);
        callback = null;
      }
    }
  );

  mediator.base.subscribe("CLEAR_CALLBACK", () => {
    callback = null;
  });
};
