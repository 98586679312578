import { SessionHint } from "@fanduel/account-contract";
import { setGlobalCallback } from "@tvg/sh-utils/callbackUtils";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import { getSportsbookRegionCookie } from "@tvg/sh-utils/sessionUtils";
import mediator from "@tvg/mediator";

import scopedMediator from "../../mediator";
import { UMEvents } from "../../mediator/types";
import { getUMEvent } from "../../mediator/events";
import type { UserInfo } from "../../../types/account/user";
import { loginModalAFEvent } from "./appsFlyerEvents";
import { getUserLocation } from "../../../hooks/useAccount/hooks/useSubscriptions/hooks/useUserLocationSubscription/actions";

export const getSession = (
  hint: SessionHint = "Normal",
  callback?: (userInfo: UserInfo) => void,
  module?: string
) => {
  const event =
    hint !== "NewUser" || !isXSell()
      ? getUMEvent(UMEvents.Session)
      : getUMEvent(UMEvents.Join);

  if (!isXSell() && !getSportsbookRegionCookie()) {
    mediator.base.dispatch(getUserLocation());

    // Wait to register the session state.
    setGlobalCallback(() => {
      setTimeout(() => {
        getSession(hint, callback, module);
      }, 100);
    });
  } else {
    if (typeof callback === "function") {
      setGlobalCallback(callback);
    }

    loginModalAFEvent(hint, module);

    scopedMediator.dispatch({
      type: event.trigger,
      payload: {
        hint
      }
    });
  }
};
